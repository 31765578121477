import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";

export async function fetchOptions(showDisabled?: boolean) {
  return Axios.get(generateUrl(`product/options/set`), {
    params: {
      connectionTypeCode: "Marketplace",
      pagesize: -1,
      showDisabled,
    },
    headers: await generateHeader(),
  });
}

export async function fetchOptionsById(
  id: number,
  showDisabled: boolean = true
) {
  return Axios.get(generateUrl(`product/options/set/${id}`), {
    params: {
      connectionTypeCode: "Marketplace",
      pagesize: -1,
      showDisabled: showDisabled,
    },
    headers: await generateHeader(),
  });
}

export async function createOption(body: any) {
  return Axios.post(
    generateUrl(`product/options/set`),
    {
      ...body,
    },
    { headers: await generateHeader() }
  );
}

export async function updateOption(body: any, id: number) {
  return Axios.put(
    generateUrl(`product/options/set/${id}`),
    {
      ...body,
    },
    { headers: await generateHeader() }
  );
}

export async function createOptionItem(body: any) {
  // delete as api cannot use it
  delete body.productId;

  return Axios.post(
    generateUrl(`product/options`),
    {
      ...body,
    },
    { headers: await generateHeader() }
  );
}

export async function updateOptionItem(body: any, id: number) {
  // remove as backend does not support this field
  delete body.id;
  delete body.productId;
  return Axios.put(
    generateUrl(`product/options/${id}`),
    {
      ...body,
    },
    { headers: await generateHeader() }
  );
}

export async function deleteOption(optionSetId: number) {
  return Axios.delete(generateUrl(`product/options/set/${optionSetId}`), {
    headers: await generateHeader(),
  });
}

export async function deleteOptionItem(optionsSetItemId: number) {
  return Axios.delete(generateUrl(`product/options/${optionsSetItemId}`), {
    headers: await generateHeader(),
  });
}

export async function disableOption(optionSetId: number, disable: boolean) {
  if (disable) {
    return Axios.delete(generateUrl(`product/options/set/${optionSetId}`), {
      headers: await generateHeader(),
    });
  } else {
    return Axios.put(
      generateUrl(`product/options/set/${optionSetId}/enable`),
      {},
      { headers: await generateHeader() }
    );
  }
}

export async function disableOptionItem(
  optionsSetItemId: number,
  disable: boolean
) {
  if (disable) {
    return Axios.put(
      generateUrl(`product/options/${optionsSetItemId}/disable`),
      {},
      { headers: await generateHeader() }
    );
  } else {
    return Axios.put(
      generateUrl(`product/options/${optionsSetItemId}/enable`),
      {},
      { headers: await generateHeader() }
    );
  }
}

export async function linkOptionSetToProduct(
  productId: number,
  optionSetId: number
) {
  return Axios.put(
    generateUrl(`product/${productId}/options/set/${optionSetId}`),
    {},
    { headers: await generateHeader() }
  );
}
