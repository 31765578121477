import { Paging, pagingDefault } from "../utils";
import { Module, ActionTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import {
  fetchOptions,
  createOption,
  updateOption,
  deleteOption,
  disableOption,
  createOptionItem,
  updateOptionItem,
  linkOptionSetToProduct,
  deleteOptionItem,
  disableOptionItem,
  fetchOptionsById,
} from "@/api/options";

export const mutations: MutationTree<OptionsState> = {
  setCreatedOptionSet(state, payload) {
    state.createdOptionSetId = payload;
  },
  setOptions(state, payload) {
    state.options = payload;
    state.loading = false;
  },
  loading(state, payload = true) {
    state.loading = payload;
  },
};

export const actions: ActionTree<OptionsState, RootState> = {
  async fetchOptions({ commit, dispatch }, showDisabled: boolean) {
    try {
      commit("loading");
      const result = await fetchOptions(showDisabled);
      commit("setOptions", result.data.results);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async createOption({ commit, dispatch }, optionData) {
    try {
      commit("loading");
      await createOption(optionData);
      dispatch("fetchOptions");
      dispatch("notifications/success", `Option ${optionData.name} created`, {
        root: true,
      });
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async createOptionSetAndLink(
    { commit, dispatch },
    data: { optionData: any; product: any }
  ) {
    try {
      commit("loading");
      // create option set
      const response = await createOption(data.optionData);
      const regex =
        /(http|ftp|https):\/\/[a-z-.]*(\/resource\/product\/options\/set\/)/g;
      const location = response.headers.location;
      const optionSetId = location.replace(regex, "");

      //console.warn("createOptionSetAndLink", data.productId, optionSetId);
      // link option set to product
      dispatch("linkOptionSetToProduct", {
        productId: data.product.id,
        optionSetId: optionSetId,
      });
      dispatch(
        "notifications/success",
        `Option ${data.optionData.name} created`,
        { root: true }
      );

      // get product to reflect new changes
      //dispatch("products/getProductById", data.product.id, { root: true });

      // reload product & options
      dispatch("fetchOptionsById", {
        optionSetId: optionSetId,
        productId: data.product.id,
      });

      return true;
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      return false;
    }
  },

  async updateOption(
    { commit, dispatch },
    data: { optionData: any; id: number; showDisabled: boolean }
  ) {
    try {
      commit("loading");
      await updateOption(data.optionData, data.id);
      dispatch("fetchOptions", data.showDisabled);
      dispatch(
        "notifications/success",
        `Option "${data.optionData.name}" updated`,
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async createOptionItem({ commit, dispatch }, optionData) {
    try {
      commit("loading");
      let productId = optionData.productId;
      await createOptionItem(optionData);

      if (productId) {
        dispatch("fetchOptionsById", {
          optionSetId: optionData.setId,
          productId: productId,
        });
      } else {
        dispatch("fetchOptions");
      }

      dispatch(
        "notifications/success",
        `Option Item ${optionData.name} created`,
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async updateOptionItem(
    { commit, dispatch },
    data: { optionItemData: any; id: number; showDisabled: boolean }
  ) {
    try {
      commit("loading");
      await updateOptionItem(data.optionItemData, data.id);
      dispatch("fetchOptions", data.showDisabled);
      dispatch(
        "notifications/success",
        `Option Item "${data.optionItemData.name}" updated`,
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async deleteOption({ commit, dispatch }, options) {
    try {
      commit("loading");
      await deleteOption(options.id);

      dispatch("fetchOptions");
      dispatch("notifications/success", `Option ${options.name} deleted`, {
        root: true,
      });
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async deleteOptionItem({ commit, dispatch }, options) {
    try {
      commit("loading");
      await deleteOptionItem(options.id);

      dispatch("fetchOptions");
      dispatch("notifications/success", `Option Item ${options.name} deleted`, {
        root: true,
      });
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async disableOption(
    { commit, dispatch },
    data: { item: any; showDisabled: boolean }
  ) {
    try {
      commit("loading");
      await disableOption(data.item.id, data.item.enabled);

      dispatch("fetchOptions", data.showDisabled);

      if (data.item.enabled) {
        dispatch("notifications/success", `Option ${data.item.name} disabled`, {
          root: true,
        });
      } else {
        dispatch("notifications/success", `Option ${data.item.name} enabled`, {
          root: true,
        });
      }
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async disableOptionItem(
    { commit, dispatch },
    data: { item: any; showDisabled: boolean }
  ) {
    try {
      commit("loading");
      await disableOptionItem(data.item.id, data.item.enabled);

      dispatch("fetchOptions", data.showDisabled);
      if (data.item.enabled) {
        dispatch(
          "notifications/success",
          `Option Item ${data.item.name} disabled`,
          { root: true }
        );
      } else {
        dispatch(
          "notifications/success",
          `Option Item ${data.item.name} enabled`,
          { root: true }
        );
      }
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  // product creation flow
  async createOptionSet({ commit, dispatch }, optionData) {
    try {
      commit("loading");
      let result = await createOption(optionData);
      let regex =
        /(http|ftp|https):\/\/[a-z-.]*(\/resource\/product\/options\/set\/)/g;
      let location = result.headers.location;
      let optionSetId = location.replace(regex, "");
      commit("setCreatedOptionSet", optionSetId);
      dispatch("notifications/success", `Option ${optionData.name} created`, {
        root: true,
      });
      return optionSetId;
    } catch (error) {
      // Option set already exists
      commit("loading", false);
      dispatch("notifications/error", error, { root: true });
    }
  },

  async createOptionSetItem(
    { commit, dispatch },
    data: { optionItemData: any; notify: boolean; reload?: boolean }
  ) {
    try {
      await createOptionItem(data.optionItemData);
      if (data.notify) {
        commit("loading", false);
        dispatch("notifications/success", `Option Items created`, {
          root: true,
        });
      }
      if (data.reload) {
        dispatch("fetchOptionsById", {
          optionSetId: data.optionItemData.setId,
        });
      }
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async updateOptionSet(
    { commit, dispatch },
    data: { optionSet: any; optionSetId: number }
  ) {
    try {
      commit("loading");
      await updateOption(data.optionSet, data.optionSetId);
      dispatch("fetchOptionsById", { optionSetId: data.optionSetId });
      dispatch(
        "notifications/success",
        `Option "${data.optionSet.name}" updated`,
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async updateOptionSetItem(
    { commit, dispatch },
    data: { optionItem: any; optionSetId: number }
  ) {
    try {
      commit("loading");
      let productId = data.optionItem.productId;
      await updateOptionItem(data.optionItem, data.optionItem.id);

      if (productId) {
        dispatch("fetchOptionsById", {
          optionSetId: data.optionSetId,
          productId: productId,
        });
      } else {
        dispatch("fetchOptionsById", { optionSetId: data.optionSetId });
      }

      dispatch(
        "notifications/success",
        `Option Item "${data.optionItem.name}" updated`,
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async disableOptionSetItem(
    { commit, dispatch },
    data: { optionItem: any; optionSetId: number; showDisabled?: boolean }
  ) {
    try {
      commit("loading");
      let productId = data.optionItem.item.productId;
      await disableOptionItem(
        data.optionItem.item.id,
        data.optionItem.item.enabled
      );

      if (productId) {
        dispatch("fetchOptionsById", {
          optionSetId: data.optionSetId,
          showDisabled: data.showDisabled,
          productId: productId,
        });
      } else {
        dispatch("fetchOptionsById", {
          optionSetId: data.optionSetId,
          showDisabled: data.showDisabled,
        });
      }

      if (data.optionItem.item.enabled) {
        dispatch(
          "notifications/success",
          `Option ${data.optionItem.item.name} disabled`,
          { root: true }
        );
      } else {
        dispatch(
          "notifications/success",
          `Option ${data.optionItem.item.name} enabled`,
          { root: true }
        );
      }
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async linkOptionSetToProduct(
    { dispatch },
    data: { productId: number; optionSetId: number }
  ) {
    try {
      await linkOptionSetToProduct(data.productId, data.optionSetId);
      dispatch("fetchOptionsById", { optionSetId: data.optionSetId });
      dispatch("notifications/success", `Option Set Linked`, { root: true });
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchOptionsById(
    { commit, dispatch },
    data: { optionSetId: number; showDisabled?: boolean; productId?: number }
  ) {
    try {
      commit("loading");
      const result = await fetchOptionsById(
        data.optionSetId,
        data.showDisabled
      );
      commit("setOptions", result.data.options);

      // Reload product edit
      if (data.productId != null) {
        dispatch("products/getProductById", data.productId, { root: true });
      }
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  refresh({ dispatch }) {
    dispatch("fetchOptions");
  },
};

export const state: OptionsState = {
  options: [],
  createdOptionSetId: null,
  loading: false,
  pagination: pagingDefault,
};

const namespaced: boolean = true;

export const options: Module<OptionsState, RootState> = {
  namespaced,
  state: state,
  getters: {},
  actions,
  mutations,
};

/** Types */
export interface Option {
  id: number;
  name: string;
  displayName: string;
  description: string;
}

export interface OptionsState {
  options: Option[];
  createdOptionSetId: number | null;
  pagination: Paging;
  loading: boolean;
}
